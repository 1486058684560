<script setup lang="ts">
// {{{ imports
import 'vue-md-player/dist/style.css'

import { Icon } from '@iconify/vue'
import _ from 'lodash'
import * as mime from 'mime-types'
import moment from 'moment'
import {
  AliyahType,
  BarMitzvahType,
  HaftarahMelodyType,
  HaftarahType,
  PronunciationType,
  SegmentType,
  SentenceGroupMelodyType,
  SentenceGroupType,
  SentenceRecordingType,
  SentenceType,
  TagType,
  TorahMelodyType,
} from 'types/types'
import { computed, isRef, nextTick, onMounted, reactive, Ref, ref, toRefs, watch } from 'vue'
import { watchEffect } from 'vue'
// import { useI18n } from 'vue-i18n'
import { useLogger } from 'vue-logger-plugin'
import { AudioPlayer, VideoPlayer } from 'vue-md-player'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'

import AnnotatedText from '@/components/AnnotatedText.vue'
import Popover from '@/components/PopOver.vue'
import PracticeSelect from '@/components/PracticeSelect.vue'
import SamplingOverlay from '@/components/SamplingOverlay.vue'
import StudentSelect from '@/components/StudentSelect.vue'
import VoiceSelect from '@/components/VoiceSelect.vue'
import { useAudioPlayer } from '@/composables/audioPlayer'
import { useChoosableTags } from '@/composables/choosableTags'
import { useFollower } from '@/composables/follower'
import { useFont } from '@/composables/font'
import { useResponsiveness } from '@/composables/responsive'
import { useSegmentation } from '@/composables/segmentation'
import { useSentences } from '@/composables/sentences'
import { useStyledRecordings } from '@/composables/styledRecording'
import { useUtil } from '@/composables/util'
import UploadPractice from '@/mutations/UploadPractice.graphql'
import store from '@/store'
import { AnnotatedSegment } from '@/util/AnnotatedSegment'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'
import { useApollo } from '@/util/apolloClient'
import { DEFAULT_PITCH_SHIFT, HTMLAudioWrapper, PitchShiftType, SentenceRecordingMetadataWithPitchedAudios } from '@/util/AudioUtil'
import { PracticeSettings } from '@/util/PracticeSettings'
import { RecorderService } from '@/util/RecorderService'
import { RecorderServiceOptions, RecordingType } from '@/util/Recording'
import { absentStyle, playBackStyle, regularStyle, SEGMENT_STYLES, userSpeakingStyle, waitingStyle } from '@/util/TextStyles'
import { allTransformations, TORAH, transformationByName, TransformationType, VOWELS, VOWELS_CANTILLATIONS } from '@/util/Transformations'
import { capitalizeFirstCharacter } from '@/util/util'
import { inReadMode, inSingMode, inTextMode, READ, SING, VoiceStyles, VoiceStyleSet } from '@/util/VoiceStyles'
// }}}
// {{{ enum, interface
enum ReadOrder {
  STUDENT_ONLY,
  STUDENT_TEACHER,
  TEACHER_STUDENT,
  TEACHER_ONLY,
}

enum PlayPhase {
  FIRST,
  SECOND,
}

interface FocusPoint {
  moment: string
  aliyah: number | null
  haftarah: number | null
  sentenceGroup: number | null
  sentence: number | null
}

interface VoiceEvent {
  voiceStartAt: number
  voiceStopAt: number
}

interface PracticeComponentData {
  aliyah: AliyahType | null
  annotatedSentences: Array<AnnotatedSentence>
  autoAdvance: boolean
  automaticallyFlippedVoiceStyle: boolean
  backgroundSampling: boolean
  backgroundSamplingPercent: number
  choosableHiddenTagsListeners: Set<() => void>
  choosableNonHiddenTagsListeners: Set<() => void>
  downloading: boolean
  finalizerHandler: NodeJS.Timeout | null
  focusAnnotatedSentence: AnnotatedSentence | null
  focusSentenceIndex: number | null
  focusSentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios | null
  focusPoints: Array<FocusPoint>
  fontSize: number
  fsmHistory: Array<string>
  fsmState: number
  haftarah: HaftarahType | null
  inRepeatMode: boolean
  jumpBackPressed: boolean
  manualForwardKeyPressed: boolean
  manualSegmentJump: boolean
  pitchShift: PitchShiftType
  playCounter: number
  playPhase: PlayPhase
  playingSentenceOrSegmentAudio: HTMLAudioWrapper | null
  popoverSegment: AnnotatedSegment | null
  popoverSegmentIndex: number | null
  popoverSentence: AnnotatedSentence | null
  popoverSentenceIndex: number | null
  popoverShowStartNow: boolean
  popoverVisible: boolean
  readOrder: ReadOrder
  readOrderOptions: Array<Record<string, unknown>>
  recordButtonPressed: boolean
  recorderService: RecorderService | null
  recordMe: boolean
  refreshAnnotatedText: number
  requiredRepeats: number
  segmentClicked: number | null
  segmentation: Array<number> | null
  selectedHiddenTag: TagType | null
  selectedNonHiddenTags: Array<TagType>
  selectedBarMitzvah: BarMitzvahType | null
  sentenceGroup: SentenceGroupType | null
  sentences: Array<SentenceType>
  silencePatience: number
  silenceStartedAt: Date | null
  recordingStartedAt: number
  soundLevel: number
  surroundLines: number
  tikkunStyle: boolean
  transformation: TransformationType | null
  uiPitchShift: number
  uploading: boolean
  uploadRecordingOverlay: boolean
  voiceEvents: Array<VoiceEvent>
  voiceSensitivity: number
  voiceStyleSet: VoiceStyleSet
  waitForVoiceEnd: boolean
  waitForVoiceStart: boolean
}

// }}}
// {{{ const
const DEBUG = false
const logger = useLogger()
const { isProduction, isTeacher } = useUtil(logger)
// const { t } = useI18n()

const REPEATS = 3
const LAST_WORD_AUTO_ADVANCE_DELAY = 1200 // milliseconds
const LAST_WORD_DELAY = 2000 // milliseconds
const NEXT_KEY = 'n'
const PREV_KEY = 'p'
const INTERWORD_PAUSE = 350 // milliseconds
const INTERSEGMENT_PAUSE = 1700 // milliseconds
const DEFAULT_SILENCE_PATIENCE = 3 // affects how slowly the orange fades
const MIN_PITCH = 0.6 // don't go lower to parselmouth
const UI_MIN_PITCH_SHIFT = 1 // multiplied by -1 in the UI
const UI_MAX_PITCH_SHIFT = 2
const { MAX_FONT_SIZE, MIN_FONT_SIZE, fontSize, lineHeight } = useFont()
const SING_NOW_POPOVER_SHOW = 1500 // ms

const state = reactive<PracticeComponentData>({
  // managed by StudentToSentencesSelector
  aliyah: null,

  // managed by annotated-text for all sentences
  annotatedSentences: [] as Array<AnnotatedSentence>,

  // advance to the next sentence when finished
  autoAdvance: false,

  automaticallyFlippedVoiceStyle: false,

  // to manage user awareness of what the recorder is doing
  backgroundSampling: false,

  backgroundSamplingPercent: 0,

  choosableHiddenTagsListeners: new Set(),
  choosableNonHiddenTagsListeners: new Set(),

  // is fetching audio
  downloading: false,

  // to manage the time after the last segment has been recorded; to give
  // the user some time to respond
  finalizerHandler: null,

  // variables associated with the sentence/recording in the current
  // practice overlay; notice that we could be tempted to make
  // focusAnnotatedSentence and/or focusSentenceRecordingMetadataWithPitchedAudios a computed
  // property derived from focusSentenceIndex. We can't. We're using
  // focusSentenceIndex to remember which sentence was hightlighted during
  // certain transitions. So these variables are not always in logical sync
  // with each other.
  focusAnnotatedSentence: null,

  focusPoints: [],

  focusSentenceIndex: null,

  focusSentenceRecordingMetadataWithPitchedAudios: null,

  fontSize: fontSize.value,

  // finite state machine state
  fsmHistory: [],
  fsmState: 0,

  haftarah: null,

  // controls the presence and target of the popover when a word has to be
  // repeated multiple times
  inRepeatMode: false,

  jumpBackPressed: false,

  manualForwardKeyPressed: false,

  // how to proceed to the next word
  manualSegmentJump: false,

  pitchShift: DEFAULT_PITCH_SHIFT,

  playCounter: 0,

  playPhase: PlayPhase.FIRST,
  // the current segment that is playing
  playingSentenceOrSegmentAudio: null,

  popoverSegment: null,

  popoverSegmentIndex: null,

  popoverSentence: null,
  popoverSentenceIndex: null,
  popoverShowStartNow: false,

  popoverVisible: false,

  // whether or not student reads first, then listens to teacher.
  readOrder: ReadOrder.STUDENT_TEACHER,

  readOrderOptions: [
    { title: 'Student only', value: ReadOrder.STUDENT_ONLY },
    {
      title: 'Student first, then teacher',
      value: ReadOrder.STUDENT_TEACHER,
    },
    {
      title: 'Teacher first, then student',
      value: ReadOrder.TEACHER_STUDENT,
    },
    { title: 'Teacher only', value: ReadOrder.TEACHER_ONLY },
  ],

  recordButtonPressed: false,

  recordMe: false,

  // access to the recorder and voice activity detector
  recorderService: null,

  recordingStartedAt: 0,

  // to trigger annotated-text to redraw
  refreshAnnotatedText: 0,

  // tracks how many times the user has to say a word
  requiredRepeats: 1,

  segmentClicked: null,

  segmentation: null,

  selectedBarMitzvah: null,

  // selection of read/sing, pronunciation, and melodic styles
  selectedHiddenTag: null,

  selectedNonHiddenTags: [],

  sentenceGroup: null,

  sentences: [],

  // results in default value for noiseMultiplierToTrigger; see util/VoiceActivityDetector.ts
  // how quickly the orange fades, i.e, how patient the system is about silences
  silencePatience: DEFAULT_SILENCE_PATIENCE,

  silenceStartedAt: null,

  soundLevel: 0,

  // how many lines to show before and after the practice line
  surroundLines: 0,

  // whether or not to enable tikkunStyle
  tikkunStyle: false,

  // to tell annotated-text how to write the hebrew
  transformation: null,

  // how far to shift the pitch
  uiPitchShift: 0,

  uploadRecordingOverlay: false,
  uploading: false,
  voiceEvents: [],
  voiceSensitivity: 85,

  voiceStyleSet: {
    haftarahMelody: null,
    pronunciation: null,
    sentenceGroupMelody: null,
    tags: [],
    torahMelody: null,
    voice: VoiceStyles[READ],
  },

  waitForVoiceEnd: false,

  waitForVoiceStart: false,
})

// {{{ computed surroundBeforeStartIndex
const surroundBeforeStartIndex = computed<number>(() => {
  if (state.focusSentenceIndex === null) {
    logger.error('unexpected null state.focusSentenceIndex')
    return 0
  }
  return Math.max(0, state.focusSentenceIndex - state.surroundLines)
})
// }}}
// {{{ computed surroundBeforeSentences
// we can't only rely on the recorderService to tell us whether we're recording
// because in TEACHER_ONLY mode we never turn it on. So in TEACHER_ONLY mode
// we rely on recordButtonPressed.
//
//
const surroundBeforeSentences = computed<Array<SentenceType>>(() => {
  if (state.focusSentenceIndex === null) {
    logger.error('unexpected null state.focusSentenceIndex')
    return []
  }
  const endSlice = Math.max(0, state.focusSentenceIndex)
  return state.sentences.slice(surroundBeforeStartIndex.value, endSlice)
})
// }}}
const refsState = toRefs(state)
const { baseLevel, currentSegmentIndex, currentSentenceIndex, currentStyle, followerOptions, followerSegmentStyle, setCurrentSegment } =
  useFollower(refsState.recorderService as Ref<RecorderService>)
const { isPlayable, loaded, pause, paused, play, playing, purge, setLoadPlay, unloaded } = useAudioPlayer(logger)
const { RESET_TO_SENTENCE, RESET_TO_WORDS, resetSegments, segmentations } = useSegmentation(
  refsState.segmentation as Ref<Array<number>>,
  surroundBeforeSentences as Ref<Array<SentenceType>>,
  logger,
)

const { recordingsInStyle } = useStyledRecordings(refsState.annotatedSentences as Ref<Array<AnnotatedSentence>>, refsState.voiceStyleSet)
const { sentencesHaveSameResponsiveness } = useResponsiveness(refsState.sentences as Ref<Array<SentenceType>>)
const { watchSentences } = useSentences(refsState.sentences, logger)
watchSentences()

const { choosableHiddenTags, choosableNonHiddenTags, choosableTags } = useChoosableTags(
  refsState.sentences,
  refsState.voiceStyleSet,
  logger,
)

const router = useRouter()
const route = useRoute()
const { apolloClient } = useApollo()
// }}}

// {{{ onBeforeRouteLeave
onBeforeRouteLeave((__, ___, next) => {
  if (isRecording.value) {
    toggleRecord()
  }
  next()
})
// }}}

onMounted(() => {
  // {{{ init
  store.dispatch.audio.setLogger(logger)
  store.dispatch.user.fetchUser()

  if (isTeacher()) {
    store.dispatch.teacher.fetchLightBarMitzvot()
  } else {
    // restore practice settings if relevant
    store.dispatch.student.fetchBarMitzvah().then(() => {
      if (store.getters.student.practiceSettings) {
        restoreStateFromPracticeSettings()
        store.dispatch.student.clearPracticeSettings()
      }
    })
  }

  // if we're coming with a long URL, store the settings and route back to /practice
  if (route.query.a || route.query.h || route.query.sg) {
    const ps: PracticeSettings = {
      a: route.query.a?.toString() || null,
      fs: route.query.fs?.toString() || null,
      h: route.query.h?.toString() || null,
      ht: route.query.ht?.toString() || null,
      nht: route.query.nht?.toString() || null,
      ro: route.query.ro?.toString() || null,
      sg: route.query.sg?.toString() || null,
      sl: route.query.sl?.toString() || null,
      t: route.query.t?.toString() || null,
      tk: route.query.tk?.toString() || null,
      v: route.query.v?.toString() || null,
    }
    store.dispatch.student.setPracticeSettings(ps)
    logger.debug(`PracticeView, onMounted, router.push ${route.path}`)
    router.push(route.path)
  }
  // }}}
  // {{{ keypress
  window.addEventListener('keydown', (e) => {
    // during recording, this interacts with the FSM
    if (isRecording.value) {
      if (e.key === NEXT_KEY && state.manualSegmentJump) {
        state.manualForwardKeyPressed = true
        // only drive the state if we're in state 16; otherwise the user interrupted the flow and we're at risk starting a
        // parallel FSM flow
        if (state.fsmState === 16) {
          driveState()
        }
        return
      }

      // in development mode we can fake voiceStart and voiceEnd events
      if (!isProduction()) {
        if (e.key === 's' && state.waitForVoiceStart) {
          state.fsmState = 6
          driveState()
        } else if (e.key === 'e' && state.waitForVoiceEnd) {
          state.fsmState = 10
          driveState()
        }
      }
    }

    // during NOT-recording this navigates between sentences
    if (state.focusSentenceIndex === null) {
      logger.error('unexpected null state.focusSentenceIndex')
      return
    }

    if (e.key === NEXT_KEY || e.key === 'ArrowRight') {
      if (hasRecordingBySentenceIndex(state.focusSentenceIndex + 1)) {
        advanceToSentence(1)
      }
    } else if (e.key === PREV_KEY || e.key === 'ArrowLeft') {
      if (hasRecordingBySentenceIndex(state.focusSentenceIndex - 1)) {
        advanceToSentence(-1)
      }
    } else if (e.key === 'Escape') {
      if (state.popoverVisible) {
        closePopover()
        return
      }

      if (state.focusAnnotatedSentence) {
        closePracticeOverlay()
      }
    }
  })
  // }}}

  // {{{ new RecorderService
  setCurrentSegment(-1, -1)
  state.transformation = allTransformations(!!state.aliyah)[0]
  state.recorderService = new RecorderService({
    ...followerOptions,
    logger: logger,
    onBaseLevel: (bl: number, perc: number) => {
      baseLevel.value = bl
      state.backgroundSamplingPercent = perc
    },

    onError: (error) => {
      store.dispatch.dialog.show({ message: error.message, title: 'Error' })
    },

    onRecordingReady: (): Promise<void> => {
      return new Promise<void>((resolve) => {
        if (!state.recordMe) {
          return resolve()
        }

        state.uploadRecordingOverlay = true
        resolve()
      })
    },

    onSoundUpdate: (n: number) => {
      if (followerOptions.onSoundUpdate) {
        followerOptions.onSoundUpdate(n)
      }
      state.soundLevel = n
    },

    onStart: () => {
      logger.debug(`recorderService.onStart, baseLevel.value = ${baseLevel.value}`)
      if (!baseLevel.value) {
        state.backgroundSampling = true
        state.backgroundSamplingPercent = 0
      }
    },

    onStartRecording: () => {
      logger.debug(`recorderService.onStartRecording, baseLevel.value = ${baseLevel.value}`)
      if (!state.recorderService) {
        logger.error('unexpected null state.recorderService in onStartRecording')
        return
      }
      state.backgroundSampling = false
      state.backgroundSamplingPercent = 0
      state.recorderService.setSensitivity(state.voiceSensitivity)
      state.recorderService.setSlowSilenceDetection(true) // go easy on student
      state.recorderService.setSlowdownEwmaMultiplier(state.silencePatience)
      state.recordingStartedAt = Date.now()

      // show the "Sing Now!" flag when the student goes first
      if (state.readOrder === ReadOrder.STUDENT_ONLY || state.readOrder === ReadOrder.STUDENT_TEACHER) {
        state.popoverShowStartNow = true
        state.popoverVisible = true
        setTimeout(() => {
          state.popoverShowStartNow = false
          state.popoverVisible = false
        }, SING_NOW_POPOVER_SHOW)
      }

      state.voiceEvents = []
      state.focusPoints = []
      pushFocusPoint()
      driveState()
    },

    onStartSilence: () => {
      state.silenceStartedAt = new Date()
    },

    onVoiceStart: () => {
      if (!sentence.value) {
        logger.error('unexpected null sentence.value in onVoiceStart')
        return
      }

      // don't respond to voice when it's just the teacher
      if (state.readOrder === ReadOrder.TEACHER_ONLY || sentence.value.isResponsive) {
        return
      }

      state.voiceEvents.push({ voiceStartAt: Date.now() - state.recordingStartedAt, voiceStopAt: 0 })
      if (followerOptions.onVoiceStart) {
        followerOptions.onVoiceStart(`practiceText:${currentSentenceIndex.value}:${currentSegmentIndex}:segment`)
      }
      if (state.waitForVoiceStart) {
        state.fsmState = 6
        driveState()
      }
    },

    onVoiceStop: (): Promise<void> => {
      logger.info('Recordings.onVoiceStop')
      return new Promise<void>((resolve) => {
        if (!sentence.value) {
          logger.error('unexpected null sentence.value in onVoiceStart')
          return
        }

        // don't respond to voice when it's just the teacher
        if (state.readOrder === ReadOrder.TEACHER_ONLY || sentence.value.isResponsive) {
          return
        }

        state.voiceEvents.push({ voiceStartAt: 0, voiceStopAt: Date.now() - state.recordingStartedAt })
        if (followerOptions.onVoiceStop) {
          followerOptions.onVoiceStop()
        }
        resolve()

        if (state.waitForVoiceEnd) {
          // Duplicated with Recording.vue
          let msSinceSilenceStarted = 0
          if (state.silenceStartedAt) {
            msSinceSilenceStarted = new Date().getTime() - state.silenceStartedAt.getTime()
          }
          state.silenceStartedAt = null // reset for next word

          setTimeout(
            () => {
              state.fsmState = 10
              driveState()
            },
            isSegmented.value
              ? Math.max(0, INTERSEGMENT_PAUSE - msSinceSilenceStarted)
              : Math.max(0, INTERWORD_PAUSE - msSinceSilenceStarted),
          )
        }
      })
    },
    setPauseStyle: false,
  } as RecorderServiceOptions)
  // }}}
  // {{{ watch recordingsInStyle
  // auto-flips from read mode to text mode on load, if there are only sung
  // recordings
  watch(
    () => recordingsInStyle,
    () => {
      // if someone chose TextMode, don't auto-flip to something else
      if (inTextMode(state.voiceStyleSet)) {
        return
      }

      nextTick(() => {
        // there are no recordings and no choosable tags, then switch readmode
        if (_.isEmpty(recordingsInStyle.value) && choosableTags.value.length <= 1 && !state.automaticallyFlippedVoiceStyle) {
          logger.debug(`watch recordingsInStyle' setting .voice = ${VoiceStyles[SING]}`)
          state.voiceStyleSet.voice = VoiceStyles[SING]
          state.automaticallyFlippedVoiceStyle = true
        }
      })
    },
  )
  // }}}
  // {{{ watch sentence
  // manual jumping needs to be off for responsive sentence; this ensures that
  // if/when the manualSegmentationJump switch is disabled, it is also set to
  // false
  watch(
    () => sentence,
    () => {
      if (sentence.value && sentence.value.isResponsive) {
        state.manualSegmentJump = false
      }
    },
    { deep: true },
  )
  // }}}
  // {{{ watch practice-select [state.aliyah, state.haftarah, state.sentenceGroup, state.sentences]
  watch(
    () => [state.aliyah, state.haftarah, state.sentenceGroup, state.sentences],
    () => {
      logger.debug('PracticeView watch state.practice-select')
      autoSetTransformation()
    },
    { deep: true },
  )
  // }}}
  // {{{ watch state.sentences
  watch(
    () => state.sentences,
    (newSentences: Array<SentenceType>, oldSentences: Array<SentenceType>): void => {
      // any sentences that are going out of scope can be released from memory
      // NB: duplicate of Recordings.watch.sentences
      const sentencesOutOfScope = _.difference(oldSentences, newSentences)
      _.forEach(sentencesOutOfScope, (sentence: SentenceType) => {
        store.dispatch.audio.releaseSentence({ sentence })
      })

      // restore the currently selected tags after a while, if they're still in
      // the choosableTags set; note that we're handling both hiddenTags and nonHiddenUserTags
      // in the same callback and silently assume that
      // choosableHiddenTagsListeners and choosableNonHiddenTagsListeners would
      // call at the same time
      if (!_.isEmpty(oldSentences)) {
        const saveTags = _.cloneDeep(state.voiceStyleSet.tags)
        const restoreTags = () => {
          _.forEach(saveTags, (oldTag: TagType) => {
            const foundTag = _.find(choosableTags.value, (choosableTag: TagType) => {
              return choosableTag.name === oldTag.name
            })

            if (foundTag) {
              if (foundTag.hidden) {
                state.selectedHiddenTag = foundTag
              } else {
                state.selectedNonHiddenTags.push(foundTag)
              }
            }
          })
        }

        state.choosableHiddenTagsListeners.add(() => {
          restoreTags()
          state.choosableHiddenTagsListeners.delete(restoreTags)
        })
      }

      logger.debug('watch state.sentences, clearing selectedHiddenTag and selectedNonHiddenTags')
      state.selectedHiddenTag = null
      state.selectedNonHiddenTags = []
      state.automaticallyFlippedVoiceStyle = false
      closePracticeOverlay()
    },
  )
  // }}}
  // {{{ watch state.aliyah
  watch(
    () => state.aliyah,
    () => {
      logger.debug('PracticeView watch state.aliyah')
      if (!store.getters.student.barMitzvah) {
        logger.error('unexpected null barMitzvah in watch.aliyah')
        return
      }

      if (!state.aliyah) {
        state.focusAnnotatedSentence = null
        state.voiceStyleSet.torahMelody = null
      } else {
        state.voiceStyleSet.torahMelody = store.getters.student.barMitzvah.torahMelody as TorahMelodyType
      }
      state.voiceStyleSet.haftarahMelody = null
      state.voiceStyleSet.sentenceGroupMelody = null
      state.automaticallyFlippedVoiceStyle = false
    },
  )
  // }}}
  // {{{ watch state.haftarah
  watch(
    () => state.haftarah,
    (): void => {
      if (!store.getters.student.barMitzvah) {
        logger.error('unexpected null barMitzvah in watch.haftarah')
        return
      }

      if (!state.haftarah) {
        state.focusAnnotatedSentence = null
        state.voiceStyleSet.haftarahMelody = null
      } else {
        state.voiceStyleSet.haftarahMelody = store.getters.student.barMitzvah.haftarahMelody as HaftarahMelodyType
      }
      state.tikkunStyle = false
      state.voiceStyleSet.torahMelody = null
      state.voiceStyleSet.sentenceGroupMelody = null
      state.automaticallyFlippedVoiceStyle = false
    },
  )
  // }}}
  // {{{ watch state.sentenceGroup
  watch(
    () => state.sentenceGroup,
    (): void => {
      if (!store.getters.student.barMitzvah) {
        logger.error('unexpected null barMitzvah in watch.sentenceGroup')
        return
      }

      if (!state.sentenceGroup) {
        state.focusAnnotatedSentence = null
        state.voiceStyleSet.sentenceGroupMelody = null
      } else {
        state.voiceStyleSet.sentenceGroupMelody = store.getters.student.barMitzvah.sentenceGroupMelody as SentenceGroupMelodyType
      }
      state.tikkunStyle = false
      state.voiceStyleSet.torahMelody = null
      state.voiceStyleSet.haftarahMelody = null
      state.automaticallyFlippedVoiceStyle = false
    },
  )
  // }}}
  // {{{ watch store.getters.student.barMitzvah
  watch(
    () => store.getters.student.barMitzvah,
    () => {
      if (!store.getters.student.barMitzvah) {
        return
      }
      state.voiceStyleSet.pronunciation = store.getters.student.barMitzvah.pronunciation as PronunciationType
      // haftarahMelody and torahMelody and sentenceGroupMelody are set in aliyah/haftarah/sentenceGroup watchers
    },
    { deep: true },
  )
  // }}}
  // {{{ watch state.surroundLines
  watch(
    () => state.surroundLines,
    (): void => {
      state.popoverVisible = false
      setCurrentSegment(surroundBeforeSentences.value.length, currentSegmentIndex.value) // resets currentSentenceIndex, but does not touch current segment
    },
  )
  // }}}
  // {{{ watchEffect recompute state.voiceStyleSet.tags from selectedHiddenTag and selectedNonHiddenTags
  watchEffect(() => {
    state.voiceStyleSet.tags = []
    if (state.selectedHiddenTag) {
      state.voiceStyleSet.tags.push(state.selectedHiddenTag)
    }
    if (!_.isEmpty(state.selectedNonHiddenTags)) {
      state.voiceStyleSet.tags = state.voiceStyleSet.tags.concat(state.selectedNonHiddenTags)
    }
  })
  // }}}
  // {{{ watch state.voiceStyleSet
  watch(
    () => state.voiceStyleSet,
    () => {
      if (isRecording.value) {
        toggleRecord()
      }

      if (state.focusAnnotatedSentence && hasRecordingByAnnotatedSentence(state.focusAnnotatedSentence as AnnotatedSentence)) {
        handleSentenceClick(state.focusAnnotatedSentence as AnnotatedSentence, state.focusSentenceIndex)
      } else {
        closePracticeOverlay()
      }
      autoSetTransformation()

      // // trigger a computed()
      // if (_.isEmpty(recordingsInStyle.value)) {
      //   // nop
      // }
    },
    { deep: true },
  )
  // }}}
  // {{{ watch choosableHiddenTags -> invoke listeners
  watch(
    () => choosableHiddenTags,
    () => {
      for (const listener of state.choosableHiddenTagsListeners) {
        logger.debug('invoking listener on watch choosableHiddenTags')
        listener()
      }
    },
    { deep: true },
  )
  // }}}
  // {{{ watch choosableNonHiddenTags -> invoke listeners
  watch(
    () => choosableNonHiddenTags,
    () => {
      for (const listener of state.choosableNonHiddenTagsListeners) {
        logger.debug('invoking listener on watch choosableNonHiddenTags')
        listener()
      }
    },
    { deep: true },
  )
  // }}}
  // {{{ watchEffect choosableNonHiddenTags -> selectedNonHiddenTags
  watchEffect(() => {
    // remove any tags that are currently selected that are no longer in the set of choosableNonHiddenTags
    state.selectedNonHiddenTags = state.selectedNonHiddenTags.filter((selectedTag: TagType | null) =>
      _.find(choosableNonHiddenTags.value, (availableTag: TagType) => selectedTag?.name === availableTag.name),
    )
  })

  // }}}
  // {{{ watchEffect choosableHiddenTags -> selectedHiddenTag
  watchEffect(() => {
    if (!state.selectedHiddenTag) {
      return
    }

    const foundTag = choosableHiddenTags.value.find((tag) => tag.name === state.selectedHiddenTag?.name)
    if (!foundTag) {
      state.selectedHiddenTag = null
    } else if (choosableHiddenTags.value.length === 1) {
      state.selectedHiddenTag = choosableHiddenTags.value[0]
    }
  })
  // }}}
  // {{{ watch state.selectedBarMitzvah
  watch(
    () => state.selectedBarMitzvah,
    (newVal, oldVal) => {
      logger.debug('PracticeView, watch state.selectedBarMitzvah =')
      logger.debug(state.selectedBarMitzvah)

      if (!newVal || oldVal !== newVal) {
        store.dispatch.student.clearBarMitzvah()
        state.aliyah = null
        state.haftarah = null
        state.sentenceGroup = null
        state.sentences = []
      }

      if (state.selectedBarMitzvah) {
        nextTick(() => {
          store.dispatch.student.setBarMitzvah(state.selectedBarMitzvah as BarMitzvahType)
        })
      }
    },
    { deep: true, immediate: true },
  )
  // }}}
})

// {{{ computed hiddenTagsStats
const hiddenTagsStats = computed<Record<string, number>>((): Record<string, number> => {
  console.log('hiddenTagsStats')
  const map: Record<string, number> = {}

  // count the number of read recordings and sung recordings for this sentence
  _.forEach(state.sentences as Array<SentenceType>, (sentence: SentenceType) => {
    if (store.getters.audio.recordings[sentence.id] === undefined) {
      console.log(`hiddenTagsStats, no recordings for ${sentence.id}`)
      return
    }

    const recs: Record<string, SentenceRecordingMetadataWithPitchedAudios> = store.getters.audio.recordings[sentence.id]
    console.log(`hiddenTagsStats, recs =`)
    console.log(recs)
    _.forEach(Object.values(recs), (srmdwpa: SentenceRecordingMetadataWithPitchedAudios) => {
      console.log('hiddenTagsStats srmdwpa =')
      console.log(srmdwpa)
      _.forEach(choosableHiddenTags.value, (hiddenTag: TagType) => {
        if (!srmdwpa.sentenceRecording) {
          logger.error('unexpected null srmdwpa.sentenceRecording')
          return
        }

        if (
          _.find(srmdwpa.sentenceRecording.tags, (tag: TagType) => {
            return tag.name === hiddenTag.name
          })
        ) {
          if (map[hiddenTag.name] === undefined) {
            map[hiddenTag.name] = 0
          }
          map[hiddenTag.name] += 1
        }
      })
    })
  })

  return map
})
// }}}
// {{{ computed isInSingMode
const isInSingMode = computed(() => {
  return inSingMode(state.voiceStyleSet)
})
// }}}
// {{{ computed isInTextMode
const isInTextMode = computed(() => {
  return inTextMode(state.voiceStyleSet)
})
// }}}
// {{{ computed isRecording
const isRecording = computed<boolean>((): boolean => {
  return (state.readOrder === ReadOrder.TEACHER_ONLY && state.recordButtonPressed) || (state.recorderService?.isRecording() ?? false)
})
// }}}
// {{{ computed isSegmented
// returns true if there is a currently selected audio fragment and it has
// an associated segmentedSentence associated with it
const isSegmented = computed<boolean>(() => {
  if (!state.focusSentenceRecordingMetadataWithPitchedAudios?.sentenceRecording) {
    logger.debug('unexpected null state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording in isSegmented')
    return false
  }

  return (
    !!state.focusSentenceRecordingMetadataWithPitchedAudios &&
    !!state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording.segmentedSentence
  )
})
// }}}
// {{{ computed popoverTarget
const popoverTarget = computed<string>(() => {
  if (isRecording.value) {
    return `practiceText:${currentSentenceIndex.value}:${currentSegmentIndex.value}:segment`
  } else {
    return `practiceText:${state.popoverSentenceIndex}:${state.popoverSegmentIndex}:segment`
  }
})
// }}}
// {{{ computed relevantTransformations
// returns a list of transformations, decide whether or not to include Torah style
const relevantTransformations = computed<Array<TransformationType>>((): Array<TransformationType> => {
  logger.info('relevantTransformations, state.aliyah =')
  logger.info(state.aliyah)
  return allTransformations(!!state.aliyah)
})
// }}}
// {{{ computed sentence
// returns the Sentence associated with focusAnnotatedSentence or null, if no such thing exists
const sentence = computed<SentenceType | null>((): SentenceType | null => {
  return state.focusAnnotatedSentence ? state.focusAnnotatedSentence.sentence() : null
})
// }}}
// {{{ computed sentencesWithSurround
const sentencesWithSurround = computed<Array<SentenceType>>(() => {
  if (!sentence.value) {
    logger.error('unexpected null sentence.value')
    return []
  }
  return _.compact(_.flattenDeep([surroundBeforeSentences.value, sentence.value, surroundAfterSentences.value]))
})
// }}}
// {{{ computed surroundAfterSentences
const surroundAfterSentences = computed<Array<SentenceType>>(() => {
  if (state.focusSentenceIndex === null) {
    logger.error('unexpected null state.focusSentenceIndex')
    return []
  }
  const startSlice = state.focusSentenceIndex + 1
  if (startSlice >= state.annotatedSentences.length) {
    return []
  }
  const endSlice = Math.min(state.annotatedSentences.length - 1, state.focusSentenceIndex + state.surroundLines)
  return state.sentences.slice(startSlice, endSlice + 1)
})
// }}}
// {{{ computed surroundOptions
const surroundOptions = computed<Array<number>>(() => {
  return Array.from(Array(state.sentences.length).keys())
})
// }}}
// {{{ computed tagWarning
// if there is tag ambiguity in the current tag selection, return a comma
// separated string of tags that the student needs to choose between to
// disambiguate the tag selection
const tagWarning = computed<string>(() => {
  if (!state.voiceStyleSet || _.isEmpty(state.voiceStyleSet.tags)) {
    return ''
  }

  // given the current tag selection, find all sentences for which there is
  // more than 1 recording; then collect all tags for all those recordings,
  // and remove the tags that have already been selected. What remains are
  // the tags that the student needs to choose from to disambiguate the
  // current tag selection.
  const tags = new Set<TagType>()
  _.forEach(
    Object.values(recordingsInStyle.value),
    (sentenceRecordingMetadataWithPitchedAudios: Array<SentenceRecordingMetadataWithPitchedAudios>) => {
      if (sentenceRecordingMetadataWithPitchedAudios.length === 1) {
        return
      }
      _.forEach(
        sentenceRecordingMetadataWithPitchedAudios,
        (sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios) => {
          if (!sentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
            return
          }

          _.forEach(sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.tags, (tag: TagType) => {
            tags.add(tag)
          })
        },
      )
    },
  )

  _.forEach(state.voiceStyleSet.tags, (tag: TagType) => {
    tags.delete(tag)
  })

  if (_.isEmpty(tags)) {
    return ''
  }

  return _.map(Array.from(tags), (tag: TagType) => tag.name).join(', ')
})
// }}}
// {{{ computed tagWarningBox
const tagWarningBox = computed<boolean>(() => {
  // count the number of current recordings with the current selection
  let nRecordings = 0
  logger.debug('tagWarningBox, recordingsInStyle.value =')
  logger.debug(recordingsInStyle.value)
  logger.debug('tagWarningBox, Object.values(recordingsInStyle.value) =')
  logger.debug(Object.values(recordingsInStyle.value))
  _.forEach(
    Object.values(recordingsInStyle.value),
    (sentenceRecordingMetadataWithPitchedAudios: Array<SentenceRecordingMetadataWithPitchedAudios>) => {
      nRecordings += sentenceRecordingMetadataWithPitchedAudios.length
    },
  )

  // there are matches; no need for a warning box
  if (nRecordings) {
    return false
  }

  // no recordings and too many tags to choose from: warning box
  if (choosableTags.value.length > 1) {
    return true
  }
  return false
})
// }}}

// {{{ restoreStateFromPracticeSettings
//
// Restores state set by the watchEffect that is modify the URL. This function is called from onMounted
// and needs to carefully maneauver through watchers on computed functions that are invoked as a result
// of setting values.
//
// Callbacks are staggered using nextTick() to give Vue time to compute values.
//
// Note that we set a listener on choosableTagsListeners
//
const restoreStateFromPracticeSettings = () => {
  // prevent the watchEffect from interfering while we set values; this gets
  // unset as part of the very last callback
  const ps: PracticeSettings | null = store.getters.student.practiceSettings

  if (!ps) {
    return
  }

  if (ps.a) {
    state.aliyah =
      _.find(store.getters.student.barMitzvah?.aliyot, (aliyah: AliyahType) => {
        return aliyah.id === ps.a
      }) || null
  }

  // note that we ignore the value of h itself; we just take this as the URL wanting to select the bar mitzvah's haftarah
  if (ps.h) {
    if (store.getters.student.barMitzvah?.haftarah) {
      state.haftarah = store.getters.student.barMitzvah?.haftarah
    }
  }

  if (ps.sg) {
    state.sentenceGroup =
      _.find(store.getters.student.barMitzvah?.sentenceGroups, (sentenceGroup: SentenceGroupType) => {
        return sentenceGroup.reading.id === ps.sg
      }) || null
  }

  if (ps.v && ps.v !== '-1') {
    state.voiceStyleSet.voice = VoiceStyles[parseInt(ps.v)]
  }

  // prepare the callback for when computed choosableHiddenTags is done.
  const setTagsFromURL = () => {
    // selectedHiddenTag
    if (ps.ht) {
      logger.debug(`onMounted choosableHiddenTags.value =`)
      logger.debug(choosableHiddenTags.value)
      state.selectedHiddenTag =
        _.find(choosableHiddenTags.value, (choosableHiddenTag: TagType) => {
          return choosableHiddenTag.name === ps.ht
        }) || null
      logger.debug(`onMounted state.selectedHiddenTag =`)
      logger.debug(state.selectedHiddenTag)
    }

    nextTick(() => {
      nextTick(() => {
        // focusSentenceIndex
        if (ps.fs) {
          const fsi = parseInt(ps.fs)
          handleSentenceClick(state.annotatedSentences[fsi] as AnnotatedSentence, fsi)
        }

        // transformation
        if (ps.t) {
          state.transformation = relevantTransformations.value[parseInt(ps.t)]
        }

        // tikkunStyle
        if (ps.tk) {
          state.tikkunStyle = ps.tk === 'true'
        }

        // state.readOrder
        if (ps.ro) {
          state.readOrder = parseInt(ps.ro)
        }

        // state.surroundLines
        if (ps.sl) {
          state.surroundLines = parseInt(ps.sl)
        }

        nextTick(() => {
          nextTick(() => {
            if (ps.nht) {
              _.forEach(_.split(ps.nht, ','), (tagId: string) => {
                const foundTag =
                  _.find(choosableNonHiddenTags.value, (choosableNonHiddenTag: TagType) => {
                    return choosableNonHiddenTag.id === tagId
                  }) || null
                if (foundTag) {
                  state.selectedNonHiddenTags.push(foundTag)
                }
              })
            }
          })
        })
      })
    })
  }

  nextTick(() => {
    // set
    state.choosableHiddenTagsListeners.add(() => {
      setTagsFromURL()
      state.choosableHiddenTagsListeners.delete(setTagsFromURL)
    })
  })
}
// }}}
// {{{ initializeRecordingState
// initializes state before and after a recording
function initializeRecordingState() {
  setCurrentSegment(currentSentenceIndex.value, -1)
  state.fsmState = 0
  state.jumpBackPressed = false
  state.waitForVoiceEnd = false
  state.waitForVoiceStart = false
  state.segmentClicked = null
  state.popoverSentence = null
  state.popoverSegment = null
  state.popoverSentenceIndex = null
  state.popoverSegmentIndex = null
  if (state.inRepeatMode) {
    state.inRepeatMode = false
  }
  state.popoverVisible = false
}
// }}}

// Finite State Machine
// {{{ driveState
// see https://app.lucidchart.com/documents/edit/fc36e7e6-b259-43fd-b7fd-e57aedc51ca0/0_0
// see docs/Practice FSM.png
function driveState() {
  if (DEBUG) {
    logger.debug(`state.fsmState = ${state.fsmState}`)
  }
  state.fsmHistory.push(state.fsmState.toString())
  if (state.readOrder !== ReadOrder.TEACHER_ONLY && !isRecording.value) {
    logger.error('abort driveState, not recording')
    return
  }

  switch (state.fsmState) {
    case 0:
      setCurrentSegment(currentSentenceIndex.value, currentSegmentIndex.value + 1)
      // turn off the popover
      if (state.inRepeatMode) {
        state.inRepeatMode = false
        state.popoverVisible = false
      }
      state.requiredRepeats = 1
      state.fsmState = 1
      driveState()
      break

    case 1:
      currentStyle.value = waitingStyle
      state.playPhase = PlayPhase.FIRST
      state.fsmState = 2
      driveState()
      break

    case 2:
      if (!sentence.value) {
        logger.error('unexpected null sentence.value')
        break
      }

      if (state.readOrder === ReadOrder.TEACHER_STUDENT || state.readOrder === ReadOrder.TEACHER_ONLY || sentence.value.isResponsive) {
        state.fsmState = 3
      } else {
        state.fsmState = 4
      }
      driveState()
      break

    case 3:
      // set currentStyle to playBackStyle
      playSegmentAndSetState(4)
      break

    case 4:
      if (state.segmentClicked) {
        state.fsmState = 7
      } else {
        state.fsmState = 22
      }
      driveState()
      break

    case 5:
      if (state.jumpBackPressed) {
        state.fsmState = 8
        state.jumpBackPressed = false
        driveState()
      } else {
        // even though user hasn't spoken yet, color change indicates that system is waiting
        currentStyle.value = waitingStyle
        state.waitForVoiceStart = true
        if (!state.recorderService) {
          logger.error('unexpected null state.recorderService')
          break
        } else if (state.readOrder !== ReadOrder.TEACHER_ONLY) {
          state.fsmHistory.push('VAD-START-5')
          state.recorderService.unpause()
        }
      }
      break

    // invoked by onVoiceStart()
    case 6:
      state.waitForVoiceStart = false
      state.fsmState = 9
      driveState()
      break

    case 7:
      if (state.finalizerHandler) {
        clearTimeout(state.finalizerHandler)
        state.finalizerHandler = null
      }
      state.requiredRepeats = 1
      // turn off the popover
      if (state.inRepeatMode) {
        state.inRepeatMode = false
        state.popoverVisible = false
      }

      if (state.segmentClicked === null) {
        logger.error('unexpected null state.segmentClicked')
        break
      }
      setCurrentSegment(currentSentenceIndex.value, state.segmentClicked)
      state.segmentClicked = null
      state.fsmState = 1
      driveState()
      break

    case 8:
      if (state.finalizerHandler) {
        clearTimeout(state.finalizerHandler)
        state.finalizerHandler = null
      }

      // if already in repeat mode, close it
      if (state.inRepeatMode) {
        state.inRepeatMode = false
        state.popoverVisible = false
      }

      state.requiredRepeats = REPEATS
      state.inRepeatMode = true
      state.jumpBackPressed = false
      nextTick(() => {
        state.popoverVisible = true
      })

      if (state.playPhase == PlayPhase.FIRST) {
        state.fsmState = 26
      } else if (state.playPhase == PlayPhase.SECOND) {
        state.fsmState = 1
      } else {
        logger.error(`unexpected state.playPhase ${state.playPhase}`)
      }
      driveState()
      break

    // See Practice.vue for documentation
    // the countdown flag [3, 2, 1, ...]
    case 9:
      currentStyle.value = userSpeakingStyle
      state.waitForVoiceEnd = true

      if (state.readOrder === ReadOrder.TEACHER_STUDENT) {
        state.playPhase = PlayPhase.SECOND
      }
      // now wait for onVoiceStop();
      break

    // invoked by onVoiceStop()
    case 10:
      state.waitForVoiceEnd = false
      state.fsmState = 23
      driveState()
      break

    case 11:
      if (state.readOrder === ReadOrder.TEACHER_STUDENT) {
        state.fsmState = 12
      } else {
        state.fsmState = 13
      }
      driveState()
      break

    case 12:
      if (state.segmentClicked) {
        state.fsmState = 7
      } else {
        state.fsmState = 14
      }
      driveState()
      break

    case 13:
      playSegmentAndSetState(12)
      state.playPhase = PlayPhase.SECOND
      break

    case 14:
      if (state.jumpBackPressed) {
        state.fsmState = 8
        state.jumpBackPressed = false
      } else {
        state.fsmState = 15
      }
      driveState()
      break

    case 15:
      if (state.manualSegmentJump) {
        state.fsmState = 16
        // user pressed it too early; don't wait for them to press it again
        if (state.manualForwardKeyPressed) {
          driveState()
        }
        // driveState() invoked by key press handler
      } else {
        state.fsmState = 17
        driveState()
      }
      break

    case 16:
      // invoked by keydown event listener
      if (state.manualForwardKeyPressed) {
        state.manualForwardKeyPressed = false
        state.fsmState = 17
        driveState()
      }
      break

    case 17:
      state.requiredRepeats -= 1
      state.fsmState = 18
      driveState()
      break

    case 18:
      if (state.requiredRepeats > 0) {
        state.fsmState = 1
      } else {
        state.fsmState = 19
      }
      driveState()
      break

    case 19:
      if (!state.focusSentenceRecordingMetadataWithPitchedAudios) {
        logger.error('unexpected null state.focusSentenceRecordingMetadataWithPitchedAudios')
        break
      }
      if (!state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
        logger.error('unexpected null state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording')
        break
      }

      if (currentSegmentIndex.value + 1 >= state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording.segments.length) {
        state.fsmState = 24
      } else {
        state.fsmState = 0
      }
      driveState()
      break

    case 20:
      // move highlight away from last word.
      // note that if we came from fsmState 25, we are already past the end
      // of the sentence; it doesn't really hurt
      setCurrentSegment(currentSentenceIndex.value, currentSegmentIndex.value + 1)

      if (state.focusSentenceIndex === null) {
        logger.error('unexpected null state.focusSentenceIndex')
        break
      }

      // auto-advance: proceed to next sentence
      if (state.autoAdvance && hasRecordingBySentenceIndex(state.focusSentenceIndex + 1)) {
        advanceToSentence(1).then(() => {
          state.fsmState = 21
          driveState()
          if (state.finalizerHandler) {
            clearTimeout(state.finalizerHandler)
            state.finalizerHandler = null
          }
        })
        break
      }

      // otherwise: this is the end
      toggleRecord()
      break

    case 21:
      setCurrentSegment(currentSentenceIndex.value, -1)
      state.fsmState = 0
      driveState()
      break

    case 22:
      if (!sentence.value) {
        logger.error('unexpected null sentence.value')
        return
      }
      if (state.readOrder === ReadOrder.TEACHER_ONLY || sentence.value.isResponsive) {
        state.fsmState = 14
      } else {
        state.fsmState = 5
      }
      driveState()
      break

    case 23:
      if (state.readOrder === ReadOrder.STUDENT_ONLY) {
        state.fsmState = 14
      } else {
        state.fsmState = 11
      }
      driveState()
      break

    case 24:
      // if we were in repeat mode, close it
      if (state.inRepeatMode) {
        state.inRepeatMode = false
        state.popoverVisible = false
      }

      if (state.readOrder === ReadOrder.STUDENT_TEACHER) {
        state.fsmState = 25
      } else {
        state.fsmState = 20
      }
      driveState()
      break

    case 25:
      // move highlight away from last word; the user may still press on Jump Back
      setCurrentSegment(currentSentenceIndex.value, currentSegmentIndex.value + 1)

      if (state.focusSentenceIndex === null) {
        logger.error('unexpected null state.focusSentenceIndex')
        break
      }

      state.finalizerHandler = setTimeout(
        () => {
          state.fsmState = 20
          driveState()
        },
        state.autoAdvance && hasRecordingBySentenceIndex(state.focusSentenceIndex + 1) ? LAST_WORD_AUTO_ADVANCE_DELAY : LAST_WORD_DELAY,
      )
      break

    case 26:
      setCurrentSegment(currentSentenceIndex.value, Math.max(0, currentSegmentIndex.value - 1))
      state.fsmState = 1
      driveState()
      break

    default:
      break
  }
}
// }}}

// stylers
// {{{ hasRecordingBySentenceIndex
// whether or not the sentence with the given index is something we can practice
function hasRecordingBySentenceIndex(sentenceIndex: number): boolean {
  if (!state.annotatedSentences) {
    logger.error('unexpected null state.annotatedSentences in hasRecordingByAnnotatedSentence')
    return false
  }

  if (sentenceIndex < 0 || sentenceIndex >= state.annotatedSentences.length) {
    return false
  }

  const annotatedSentence = state.annotatedSentences[sentenceIndex]
  return hasRecordingByAnnotatedSentence(annotatedSentence as AnnotatedSentence)
}
// }}}
// {{{ hasRecordingByAnnotatedSentence
// returns whether or not a recording is associated with this sentence
function hasRecordingByAnnotatedSentence(annotatedSentence: AnnotatedSentence | null): boolean {
  // do not involve state.downloading as there is a race condition between
  // clicking on a sentence to practice it and the audio pre-fetching that
  // occurs
  if (!annotatedSentence) {
    logger.error('unexpected null annotatedSentence in hasRecordingByAnnotatedSentence')
    return false
  }

  return !inTextMode(state.voiceStyleSet) && annotatedSentence.sentence().id in recordingsInStyle.value
}
// }}}

// {{{ autoSetTransformation
// if user fiddled the read/sing button, then set the appropriate
// transformation, but only if it was previously set on the expected
// value (ie, VOWELS for Read; and VOWELS_CANTILLATIONS for Sing) if
// user had changed it. don't change it if the user had set it to
// a non-standard transformation
function autoSetTransformation() {
  // sentenceGroup || haftarah + torah -> vowels, can
  if (!state.transformation) {
    logger.error('unexpected null state.transformation')
    return
  }

  if ((state.haftarah || state.sentenceGroup) && state.transformation.name === transformationByName(TORAH).name) {
    state.transformation = transformationByName(VOWELS_CANTILLATIONS)
  }

  // sing + vowels-only -> set to vowels, cantillations
  if (state.voiceStyleSet.voice === VoiceStyles[SING] && state.transformation.name === transformationByName(VOWELS).name) {
    state.transformation = transformationByName(VOWELS_CANTILLATIONS)

    // read + vowels, cantillations -> set to vowels-only
  } else if (
    state.voiceStyleSet.voice === VoiceStyles[READ] &&
    state.transformation.name === transformationByName(VOWELS_CANTILLATIONS).name
  ) {
    state.transformation = transformationByName(VOWELS)
  }
}
// }}}
// {{{ setVoiceSensitivity
// sets the VAD voice sensitivity
function setVoiceSensitivity(value: number) {
  state.voiceSensitivity = value
  if (state.recorderService) {
    state.recorderService.setSensitivity(value)
  }
}
// }}}
// {{{ setSilencePatience
// sets the VAD silence patience
function setSilencePatience(value: number) {
  state.silencePatience = value
  if (state.recorderService) {
    state.recorderService.setSlowdownEwmaMultiplier(state.silencePatience)
  }
}
// }}}

// {{{ copyUrlToClipboard
const copyUrlToClipboard = (): void => {
  const a = state.aliyah?.id ?? ''
  const h = state.haftarah?.reading.id ?? ''
  const sg = state.sentenceGroup?.reading.id ?? ''
  const ht = state.selectedHiddenTag?.name ?? ''
  const nht = state.selectedNonHiddenTags
    ? _.join(
        _.map(state.selectedNonHiddenTags, (tag: TagType) => {
          return tag.id
        }),
        ',',
      )
    : ''
  const fs = state.focusSentenceIndex ?? ''
  const tk = state.tikkunStyle
  const ro = state.readOrder
  const sl = state.surroundLines
  const t = _.findIndex(relevantTransformations.value, (transformation: TransformationType) => {
    return transformation.name === state.transformation?.name
  })
  const v = _.indexOf(VoiceStyles, state.voiceStyleSet.voice) ?? ''

  const url = `${window.location.origin}${route.path}?a=${a}&h=${h}&sg=${sg}&v=${v}&ht=${ht}&fs=${fs}&t=${t}&tk=${tk}&ro=${ro}&sl=${sl}&nht=${nht}`
  navigator.clipboard
    .writeText(url)
    .then(() => {
      store.dispatch.snackbar.add({ message: 'Practice URL copied to clipboard', state: 'primary' })
    })
    .catch(() => {})
}
// }}}

// annotated-text stylers
// {{{ styles
// styles sentences to differentiate between sentences with- and without recordings
function sentenceStyle(__: string, annotatedSentence: AnnotatedSentence): string {
  return inTextMode(state.voiceStyleSet) || hasRecordingByAnnotatedSentence(annotatedSentence) ? regularStyle : absentStyle
}
// }}}
// {{{ segmentBoundaryStyle
function segmentBoundaryStyle(
  style: string,
  __: AnnotatedSentence,
  ___: AnnotatedSegment,
  sentenceIndex: number,
  segmentIndex: number,
): string {
  if (isRecording.value) {
    return style
  }
  return sentenceIndex === currentSentenceIndex.value ? SEGMENT_STYLES[segmentIndex % 2] : style
}
// }}}
// {{{ segmentStyle
// during recording (reading): segments are being styled to follow the user.
// during recording (singing): regular style
// during non-recording: segments are clickable in read style; otherwise
function segmentStyle(
  style: string,
  annotatedSentence: AnnotatedSentence,
  annotatedSegment: AnnotatedSegment,
  sentenceIndex: number,
  segmentIndex: number,
): string {
  // we're being asked about the style of a sentence that isn't the focus sentence; it's regular
  logger.debug('segmentStyle, style =')
  logger.debug(style)
  logger.debug('segmentStyle, annotatedSentence =')
  logger.debug(annotatedSentence)
  logger.debug('segmentStyle, annotatedSegment =')
  logger.debug(annotatedSegment)
  logger.debug('segmentStyle, sentenceIndex =')
  logger.debug(sentenceIndex)
  logger.debug('segmentStyle, segmentIndex =')
  logger.debug(segmentIndex)
  const nPreSentences = surroundBeforeSentences.value.length
  if (state.focusSentenceIndex !== null && sentenceIndex !== nPreSentences) {
    logger.debug('segmentStyle, return regularStyle')
    return regularStyle
  }

  // if we're recurding, styling segments is the responsibility of the follower
  if (isRecording.value) {
    logger.debug('segmentStyle, return followerSegmentStyle')
    return followerSegmentStyle(style, annotatedSegment, sentenceIndex, segmentIndex)
  }

  // alternate segment coloring
  if (segmentIndex !== currentSegmentIndex.value) {
    logger.debug('segmentStyle, return SEGMENT_STYLES[]')
    return SEGMENT_STYLES[segmentIndex % 2]
  }

  logger.debug('segmentStyle, return clickableSegment ? style : absentStyle')
  return clickableSegment(annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex) ? style : absentStyle
}
// }}}

// {{{ clickableSentence
function clickableSentence(__: AnnotatedSentence, sentenceIndex: number): boolean {
  if (isRecording.value) {
    return false
  }

  const absoluteSentenceIndex = surroundBeforeStartIndex.value + sentenceIndex
  return hasRecordingBySentenceIndex(absoluteSentenceIndex) && absoluteSentenceIndex !== state.focusSentenceIndex
}
// }}}
// {{{ clickableSegment
// this is only used by the practice overlay; it helps render the mouse
// pointer as clickable when there's an audio file associated with a segment.
function clickableSegment(
  annotatedSentence: AnnotatedSentence,
  __: AnnotatedSegment,
  sentenceIndex: number,
  segmentIndex: number,
): boolean {
  logger.info('clickableSegment')

  // can't click on audio that's still downloading
  if (state.downloading) {
    return false
  }

  // can't click on individual segments in a sentence that isn't the current focus
  const absoluteSentenceIndex = surroundBeforeStartIndex.value + sentenceIndex
  if (absoluteSentenceIndex !== state.focusSentenceIndex) {
    return false
  }

  // can't click on a segment that's already the current segment
  if (segmentIndex === currentSegmentIndex.value) {
    return false
  }

  if (!recordingsInStyle.value[annotatedSentence.sentence().id]) {
    logger.error('unexpect vull recordingsInStyle.value[annotatedSentence.sentence().id]')
    return false
  }

  if (!recordingsInStyle.value[annotatedSentence.sentence().id].length) {
    logger.error('unexpect zero length recordingsInStyle.value[annotatedSentence.sentence().id].length')
    return false
  }

  if (recordingsInStyle.value[annotatedSentence.sentence().id].length > 1) {
    logger.error('unexpect >1 length recordingsInStyle.value[annotatedSentence.sentence().id]')
    return false
  }

  return isPlayable({
    pitchShift: state.pitchShift,
    segmentIndex,
    sentenceRecordingMetadataWithPitchedAudios: recordingsInStyle.value[annotatedSentence.sentence().id][0],
  })
}
// }}}

// UI handlers
// {{{ advanceToSentence
// used by the Previous/Next navigation arrows in the practice overlay;
// essentially simulates a click on the Previous/Next sentence
function advanceToSentence(increment: number, absolute = false): Promise<void> {
  state.popoverVisible = false
  return new Promise((resolve) => {
    if (state.focusSentenceIndex === null) {
      logger.error('unexpected null state.focusSentenceIndex')
      return
    }

    const nextSentenceIdx = absolute ? increment : state.focusSentenceIndex + increment
    const nextSentence = state.annotatedSentences[nextSentenceIdx] as AnnotatedSentence
    handleSentenceClick(nextSentence, nextSentenceIdx).then(() => {
      if (isRecording.value) {
        pushFocusPoint()
      }
      resolve()
    })
  })
}
// }}}
// {{{ closePopover
function closePopover() {
  purge()
  state.popoverSegment = null
  state.popoverVisible = false
}
// }}}
// {{{ closePracticeOverlay
// closes the practice overlay to pop back to all-text view
function closePracticeOverlay(): void {
  if (isRecording.value) {
    toggleRecord()
  }

  state.focusAnnotatedSentence = null
  state.focusSentenceIndex = null
  state.focusSentenceRecordingMetadataWithPitchedAudios = null
  state.segmentation = null
  if (playing()) {
    pause()
  }
  state.popoverSegment = null
  state.popoverSegmentIndex = null
  state.popoverSentenceIndex = null
  state.popoverSentence = null
  state.popoverVisible = false
}
// }}}
// {{{ handleSentenceClick
// transitions from all-sentences view to focus view
function handleSentenceClick(annotatedSentence: AnnotatedSentence, sentenceIndex: number | null): Promise<void> {
  return new Promise((resolve) => {
    logger.info('handleSentenceClick')
    state.fsmHistory.push('SenC')
    if (!hasRecordingByAnnotatedSentence(annotatedSentence)) {
      return
    }

    if (sentenceIndex === null) {
      logger.error('unexpected null sentenceIndex in handleSentenceClick')
      return
    }

    // to remember which sentence out of all sentences we're focusing
    // on, use focusSentenceIndex.
    state.focusSentenceIndex = sentenceIndex
    state.focusAnnotatedSentence = state.annotatedSentences[sentenceIndex]

    if (!sentence.value) {
      logger.error('unexpected null sentence.value in handleSentenceClick')
      return
    }

    state.focusSentenceRecordingMetadataWithPitchedAudios = recordingsInStyle.value[sentence.value.id][0]
    logger.debug('handleSentenceClick, state.focusSentenceRecordingMetadataWithPitchedAudios =')
    logger.debug(state.focusSentenceRecordingMetadataWithPitchedAudios)

    if (!state.focusSentenceRecordingMetadataWithPitchedAudios) {
      logger.debug('unexpected null state.focusSentenceRecordingMetadataWithPitchedAudios')
      return
    }

    const refAnnotatedSentence: Ref<AnnotatedSentence> = isRef(annotatedSentence)
      ? (annotatedSentence as Ref<AnnotatedSentence>)
      : (ref(annotatedSentence) as Ref<AnnotatedSentence>)

    // set default segmentation
    if (!isSegmented.value) {
      logger.debug('handleSentenceClick, not segmented')
      resetSegments(inReadMode(state.voiceStyleSet) ? RESET_TO_WORDS : RESET_TO_SENTENCE, refAnnotatedSentence)
    } else if (!state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.segmentedSentence) {
      logger.debug('handleSentenceClick, segmented, but null sentenceRecording.segmentedSentence')
      logger.error('unexpected null state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.segmentedSentence')
      return
    } else {
      logger.debug('handleSentenceClick, looping over segments')
      state.segmentation = [] as Array<number>
      _.forEach(
        _.sortBy(
          state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording.segmentedSentence.segments,
          (segment: SegmentType) => segment.index,
        ),
        (segment: SegmentType) => {
          state.segmentation!.push(segment.words)
        },
      )
    }

    // if anything was playing, shut it up
    if (playing()) {
      pause()
    }

    // currentSentenceIndex is being used the followerMixin to highlight segments as
    // they are being read during practice.
    setCurrentSegment(surroundBeforeSentences.value.length, -1)

    // loads all audio files from the backend and then kicks the annotated-text component
    // because it may decide to render differently depending on whether certain segments
    // have an available audio or not.
    state.downloading = true
    logger.debug('handleSentenceClick, calling fetchAudio')
    store.dispatch.audio
      .fetchAudio({
        cache: true,
        pitchShift: state.pitchShift,
        sentenceRecording: state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording as SentenceRecordingType,
      })
      .then(() => {
        state.downloading = false
        state.refreshAnnotatedText += 1
        resolve()
      })

    // prefetch the next sentence, for smooth transition in case of auto-advance
    const nextIndex = sentenceIndex + 1
    if (nextIndex < state.annotatedSentences.length) {
      const nextAnnotatedSentence = state.annotatedSentences[nextIndex]
      if (recordingsInStyle.value[nextAnnotatedSentence.sentence().id]) {
        const [nextSentenceRecordingMetadataWithPitchedAudios] = recordingsInStyle.value[nextAnnotatedSentence.sentence().id]
        store.dispatch.audio.fetchAudio({
          cache: true,
          pitchShift: state.pitchShift,
          sentenceRecording: nextSentenceRecordingMetadataWithPitchedAudios.sentenceRecording as SentenceRecordingType,
        }) // don't wait for result
      }
    }

    // // Scroll to the practice overlay on the nextTick, as a hidden component
    // // won't reactively respond.
    // state.$nextTick(() => {
    //   state.$refs.practiceSpan.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'center',
    //     inline: 'nearest',
    //   });
    // });
  })
}
// }}}
// {{{ handleJumpBack
// this jumps back to the previous word and expect the user to say it N times
function handleJumpBack(): void {
  // stop the audio that's playing as part of practicing
  logger.debug('handleJumpBack')
  state.fsmHistory.push('J')
  if (playing()) {
    pause()
  }

  // we may have pressed the jump back button while voice was being detected.
  // to avoid an immediate voice detection in the state machine we now reset //
  // the VAD so that the user actively needs to make sound again to trigger // the
  // VAD
  if (state.recorderService) {
    state.recorderService.reset()
  }

  // this prevents asynchronous callbacks from messing with our FSM
  state.popoverVisible = false
  state.waitForVoiceEnd = false
  state.waitForVoiceStart = false

  state.jumpBackPressed = true
  state.fsmState = 8
  driveState()
}
// }}}
// {{{ handleSegmentBoundaryClick
// a user may click on a segment boundary when trying to switch between
// focused sentences; handle those situations and inform the caller
// whether focus switched; this return value is used by handleSegmentClick
function handleSegmentBoundaryClick(
  annotatedSentence: AnnotatedSentence,
  __: AnnotatedSegment,
  sentenceIndex: number,
  ___: number,
): boolean {
  if (isRecording.value) {
    return false
  }

  // if clicked on a sentence that isn't the currently focus sentence, then switch
  const absoluteSentenceIndex = surroundBeforeStartIndex.value + sentenceIndex
  if (absoluteSentenceIndex !== state.focusSentenceIndex) {
    handleSentenceClick(annotatedSentence, absoluteSentenceIndex)
    return true
  }
  return false
}
// }}}
// {{{ handleSegmentClick
// plays the recording associated with the word.
function handleSegmentClick(
  annotatedSentence: AnnotatedSentence,
  annotatedSegment: AnnotatedSegment,
  sentenceIndex: number,
  segmentIndex: number,
): void {
  logger.info('handleSegmentClick')
  state.fsmHistory.push('SegC')

  // can't click during practice on a downloading audio segment
  if (state.downloading) {
    return
  }

  // if audio is playing, stop it
  if (playing()) {
    pause()
  }

  // if the user clicked to refocus sentence based on surroundLines, then we're done
  if (handleSegmentBoundaryClick(annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex)) {
    return
  }

  if (!isRecording.value) {
    if (annotatedSegment !== state.popoverSegment) {
      // close popover if another segment was clicked
      if (state.popoverVisible) {
        state.popoverVisible = false
        state.popoverSegment = null
      }
      nextTick(() => {
        nextTick(() => {
          state.popoverSentence = annotatedSentence
          state.popoverSegment = annotatedSegment
          state.popoverSentenceIndex = sentenceIndex
          state.popoverSegmentIndex = segmentIndex
          state.popoverVisible = true
        })
      })
    }

    const paramsToPlaySentenceOrSegmentAudio = {
      pitchShift: state.pitchShift,
      segmentIndex,
      sentence: annotatedSentence.sentence(),
      sentenceRecordingMetadataWithPitchedAudios: recordingsInStyle.value[annotatedSentence.sentence().id][0],
    }
    logger.debug('calling pauseCurrentSegmentOrPlayOtherSegment with paramsToPlaySentenceOrSegmentAudio =')
    logger.debug(paramsToPlaySentenceOrSegmentAudio)
    setLoadPlay(paramsToPlaySentenceOrSegmentAudio)
      .then(() => {})
      .catch(() => {
        logger.error('unexpected catch in handleSegmentClick')
      })
    return
  }

  state.segmentClicked = segmentIndex
  state.fsmState = 7
  driveState()
}
// }}}

// audio
// {{{ toggleRecord
// toggles the recorder function on/off
function toggleRecord(): void {
  logger.debug('toggleRecord')
  // stop the audio that's playing as part of practicing
  if (playing()) {
    pause()
  }

  if (isRecording.value) {
    logger.debug('toggleRecord: isRecording.value true')

    if (state.finalizerHandler) {
      clearTimeout(state.finalizerHandler)
      state.finalizerHandler = null
    }

    // if we were sampling and it got cancelled, we don't have a baseline
    if (state.backgroundSampling) {
      baseLevel.value = null
    }

    if (!state.recorderService) {
      logger.error('unexpected null state.recorderService in toggleRecord')
      return
    }

    state.recorderService.stopRecording()
    state.popoverShowStartNow = false
    state.backgroundSampling = false
    state.backgroundSamplingPercent = 0
    state.recordButtonPressed = false
    initializeRecordingState()
  } else if (!state.recorderService?.options) {
    logger.error('unexpected null state.recorderService.options')
  } else {
    initializeRecordingState()
    state.fsmHistory = []

    // in all but Teacher-only mode we'll  need the VAD
    if (state.readOrder !== ReadOrder.TEACHER_ONLY) {
      state.recorderService.options.voiceActivityDetection = true
      // don't re-sample baseLevel
      // baseLevel.value = 0
      state.recorderService.startRecording(baseLevel.value ?? 0)
    } else {
      state.fsmState = 0
      driveState()
    }
  }
}
// }}}
// {{{ refetchAudio
function refetchAudio() {
  // map negative numbers into the range MIN_PITCH..1
  // map positive numbers into the range 1..2 (I think?)
  state.pitchShift = (
    state.uiPitchShift < 0
      ? 1.0 - Math.abs(state.uiPitchShift / UI_MIN_PITCH_SHIFT) * (1.0 - MIN_PITCH)
      : (state.uiPitchShift + UI_MAX_PITCH_SHIFT) / UI_MAX_PITCH_SHIFT
  ).toFixed(1)

  if (!state.focusSentenceRecordingMetadataWithPitchedAudios) {
    logger.info('null state.focusSentenceRecordingMetadataWithPitchedAudios')
    return
  }

  if (!state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
    logger.info('null state.focusSentenceRecordingMetadataWithPitchedAudios')
    return
  }

  if (!state.focusAnnotatedSentence) {
    logger.info('null state.focusAnnotatedSentence')
    return
  }

  state.downloading = true
  store.dispatch.audio
    .fetchAudio({
      cache: true,
      pitchShift: state.pitchShift,
      sentenceRecording: state.focusSentenceRecordingMetadataWithPitchedAudios.sentenceRecording,
    })
    .then(() => {
      state.downloading = false
      state.refreshAnnotatedText += 1
    })
}
// }}}
// {{{ playSegmentAndSetState
//
// This is tricky and complicated. We're doing on asynchronous call here to
// play() and while the audio plays, all kinds of things could happen,
// including another call to playSegmentAndSetState. So we're tracking a "call
// counter" (currentPlayCounter) to ensure that only the most recent call to
// playSegmentAndSetState will cause the FSM to move forward
//
function playSegmentAndSetState(fsmState: number) {
  state.playCounter += 1
  const currentPlayCounter = state.playCounter
  const currentFsmState = state.fsmState

  if (!state.recorderService) {
    logger.error('unexpected null state.recorderService in playSegmentAndSetState')
    return
  }

  if (!sentence.value) {
    logger.error('unexpected null sentence.value')
    return []
  }

  state.fsmHistory.push('VAD-STOP')
  if (state.readOrder !== ReadOrder.TEACHER_ONLY) {
    state.recorderService.pause()
  }
  currentStyle.value = playBackStyle
  setLoadPlay({
    pitchShift: state.pitchShift,
    segmentIndex: currentSegmentIndex.value,
    sentenceRecordingMetadataWithPitchedAudios: recordingsInStyle.value[sentence.value.id][0],
  })
    .then(() => {
      const makeJump = currentPlayCounter === state.playCounter && currentFsmState === state.fsmState
      state.fsmHistory.push(`CB [${currentFsmState} -> ${fsmState} @ ${state.fsmState}: ${makeJump}]`)
      if (!makeJump) {
        logger.debug('setLoadPlay came back to another fsmState than it startedt at; not driving FSM')
        return
      }

      state.fsmState = fsmState
      driveState()
    })
    .catch(() => {
      logger.error('unexpected catch on setLoadPlay')
      return
    })
}
// }}}

// upload practice
// {{{ pushFocusPoint
const pushFocusPoint = () => {
  state.focusPoints.push({
    aliyah: state.aliyah ? parseInt(state.aliyah.id) : null,
    haftarah: state.haftarah ? parseInt(state.haftarah.reading.id) : null,
    moment: String(Date.now()),
    sentence: sentence.value !== null ? parseInt(sentence.value.id) : null,
    sentenceGroup: !_.isEmpty(state.sentenceGroup) ? parseInt(state.sentenceGroup.reading.id) : null,
  } as FocusPoint)
}
// }}}
// {{{ upload
// uploads the file to the backend
function upload(file: File): void {
  state.uploading = true
  apolloClient
    .mutate({
      mutation: UploadPractice,
      variables: {
        aliyah: state.aliyah ? parseInt(state.aliyah.id) : null,
        file,
        focusPoints: state.focusPoints,
        haftarah: state.haftarah ? parseInt(state.haftarah.reading.id) : null,
        sentence: sentence.value !== null ? parseInt(sentence.value.id) : null,
        sentenceGroup: !_.isEmpty(state.sentenceGroup) ? state.sentenceGroup.reading.id : null,
        voiceEvents: state.voiceEvents,
      },
    })
    .then((result) => {
      if (result.data.uploadPractice.errors) {
        store.dispatch.dialog.show({ message: `Upload failed. Error: ${result.data.uploadFile.errors}`, title: 'Error' })
      } else {
        // TBD
      }
    })
    .catch((error) => {
      store.dispatch.dialog.show({ message: `Upload failed. Error: ${error}`, title: 'Error' })
    })
    .finally(() => {
      // TBD
    })
}
// }}}
// {{{ uploadPractice
// callback of the recorderService; we use it to create a new
// assistantRecording and upload it.
function uploadPractice(recording: RecordingType): Promise<void> {
  return new Promise((resolve) => {
    // this can happen if the recording stopped because the recording overlay was abruptly closed
    if (!sentence.value) {
      logger.debug('null focusSentence or focusSentenceIndex in uploadPractice; calling resolve()')
      resolve()
      return
    }

    const file = new Blob([recording.blob], { type: recording.mimeType })
    const extension = mime.extension(file.type)

    let fileName = ''
    if (state.aliyah) {
      fileName += `Par-${state.aliyah.parashah.transliteratedTitle}-Ali-${state.aliyah.rank}`
    } else if (state.haftarah) {
      fileName += `Haf-${state.haftarah.parashah.transliteratedTitle}`
    } else if (state.sentenceGroup) {
      fileName += `Txt-${state.sentenceGroup.reading.description}`
    }
    fileName += `-Sen-${state.focusSentenceIndex! + 1}`
    fileName += `-Pron-${state.voiceStyleSet.pronunciation?.description}`
    fileName += `-${moment().format('YYYY-MM-DD-hh-mm-ss')}`
    fileName += `.${extension}`
    ;(file as any).name = fileName
    upload(file)
    resolve()
  })
}
// }}}
</script>

<template>
  <!-- {{{ template -->
  <v-container fluid>
    <v-dialog v-model="state.uploadRecordingOverlay" width="auto">
      <v-card max-width="auto">
        <v-card-title>Share recording?</v-card-title>
        <v-row>
          <v-col><v-card-text> Foo bar </v-card-text></v-col>
          <audio-player src="./foo.mp3" />
          <v-card-actions>
            <v-btn color="primary" @click="state.uploadRecordingOverlay = false">Close</v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-dialog>
    <v-progress-circular v-if="store.getters.student.loading" indeterminate />

    <v-row v-if="isTeacher()">
      <v-col md="4">
        <student-select
          ref="barMitzvahSelect"
          v-model:barMitzvah="state.selectedBarMitzvah"
          :bar-mitzvah-clearable="true"
          :external-set="true"
        />
      </v-col>
    </v-row>

    <div v-if="store.getters.student.barMitzvah">
      <sampling-overlay
        v-if="state.backgroundSampling"
        :on-click="
          () => {
            baseLevel = 0
            toggleRecord()
          }
        "
        :percent="state.backgroundSamplingPercent"
        :visible="state.backgroundSampling"
      />

      <!-- text/recordings selection -->
      <v-row>
        <v-col md="3" sm="5">
          <!-- Text -->
          <practice-select
            v-model:aliyah="state.aliyah"
            v-model:bar-mitzvah="store.getters.student.barMitzvah"
            v-model:haftarah="state.haftarah"
            v-model:sentence-group="state.sentenceGroup"
            v-model:sentences="state.sentences"
          />
        </v-col>
        <template v-if="!store.getters.student.loading && store.getters.audio.loading">
          <v-col class="mt-6" md="3">
            <v-progress-circular indeterminate />
          </v-col>
        </template>
        <template v-else-if="!!state.aliyah || !!state.haftarah || !!state.sentenceGroup">
          <!-- Practice: Reading/Singing/Text -->
          <v-col md="3">
            <voice-select
              v-model:voice="state.voiceStyleSet.voice"
              :annotated-sentences="state.annotatedSentences as Array<AnnotatedSentence>"
              :recordings="recordingsInStyle"
              :voice-style-set="state.voiceStyleSet"
            />
          </v-col>

          <!-- style selection -->
          <template v-if="!isInTextMode">
            <v-col md="3">
              <v-radio-group v-if="state.voiceStyleSet.voice && !_.isEmpty(choosableHiddenTags)" v-model="state.selectedHiddenTag">
                <template #label>
                  <span class="text-caption"> Units </span>
                </template>
                <v-radio
                  v-for="tag in choosableHiddenTags"
                  :key="tag.name"
                  :label="`${capitalizeFirstCharacter(tag.name)} (${hiddenTagsStats[tag.name]} recording${hiddenTagsStats[tag.name] === 1 ? '' : 's'})`"
                  :value="tag"
                />
              </v-radio-group>
            </v-col>
            <v-col
              v-if="state.voiceStyleSet.voice && state.selectedHiddenTag && !_.isEmpty(choosableNonHiddenTags)"
              md="3"
              :style="tagWarningBox ? 'border: 2px solid red' : ''"
            >
              <v-select
                v-model="state.selectedNonHiddenTags"
                chips
                clearable
                :disabled="!choosableNonHiddenTags || choosableNonHiddenTags.length <= 1"
                item-title="name"
                :items="choosableNonHiddenTags"
                label="Tags"
                :multiple="true"
                return-object
                variant="underlined"
              >
                <template #chip="{ props, item }">
                  <v-chip v-bind="props" closable :text="item.raw.name" />
                </template>
              </v-select>
            </v-col>
          </template>
        </template>
      </v-row>

      <!-- visual text manipulations -->
      <v-row v-if="!inTextMode(state.voiceStyleSet) && !_.isEmpty(recordingsInStyle) && !tagWarning && state.sentences.length">
        <v-col lg="1" md="2" sm="2">
          <v-select
            v-model="state.transformation"
            class="mt-n4"
            :disabled="state.annotatedSentences.length === 0"
            :items="relevantTransformations"
            label="Script"
            return-object
            variant="solo"
          >
            <template #selection="{ item }">
              <v-list-item-title :class="[item.raw.props.class, 'text-h5']">
                {{ item.title }}
              </v-list-item-title>
            </template>

            <template #item="{ item, props }">
              <v-list-item v-bind="props" title="">
                <v-list-item-title :class="[props.class, 'text-h5']">
                  {{ item.title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.raw.subtitle }}
                </v-list-item-subtitle>
              </v-list-item>
            </template>
          </v-select>
        </v-col>

        <v-col v-if="!!state.aliyah" md="1">
          <v-switch v-model="state.tikkunStyle" class="mt-n1" color="primary">
            <template #prepend>
              <Icon icon="emojione-monotone:scroll" width="1.3em" />
            </template>
          </v-switch>
        </v-col>

        <v-col class="mt-2" md="2">
          <v-slider v-model="state.fontSize" color="primary" :max="MAX_FONT_SIZE" :min="MIN_FONT_SIZE" step="10" thumb-label>
            <template #label="props">
              <v-icon v-bind="props"> mdi-format-size </v-icon>
            </template></v-slider
          >
        </v-col>

        <v-col v-if="isInSingMode && !!state.focusAnnotatedSentence" md="2">
          <v-tooltip bottom open-delay="500" :text="$t('message.pitchChange')">
            <template #activator="{ props }">
              <span v-bind="props">
                <v-slider
                  v-model="state.uiPitchShift"
                  class="mt-1"
                  color="primary"
                  :max="UI_MAX_PITCH_SHIFT"
                  :min="-1 * UI_MIN_PITCH_SHIFT"
                  step="0.1"
                  thumb-label
                  @update:model-value="refetchAudio"
                >
                  <template #label>
                    <span style="font-size: 1.5em; font-weight: bold">♭/♯</span>
                  </template></v-slider
                >
              </span>
            </template>
          </v-tooltip>
        </v-col>

        <v-col offset="1">
          <v-tooltip text="Copy homework settings into URL">
            <template #activator="{ props }">
              <v-btn :color="isTeacher() ? 'blue-lighten-4' : ''" v-bind="props" @click="copyUrlToClipboard"
                >{{ isTeacher() ? 'Homework URL' : 'Share' }}<v-icon class="ml-2">mdi-share</v-icon></v-btn
              >
            </template>
          </v-tooltip>
        </v-col>
      </v-row>

      <template v-if="DEBUG && !_.isEmpty(state.fsmHistory)">
        <v-row class="my-4">
          <v-col>
            baseLevel: {{ baseLevel && baseLevel.toFixed(2) }} <br />
            soundLevel: {{ state.soundLevel.toFixed(2) }}<br />
            state.backgroundSampling: {{ state.backgroundSampling }}<br />
            isRecording: {{ isRecording }}<br />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12"> FSM: {{ state.fsmHistory }} <br /> </v-col>
        </v-row>
      </template>

      <template v-if="!!sentence && (!!state.aliyah || !!state.haftarah || !!state.sentenceGroup)">
        <v-row class="mt-n4 pt-n4">
          <v-col lg="12" md="12" xl="10">
            <v-sheet class="pr-3 pt-2 pb-0" elevation="2" fluid>
              <v-row justify="end" no-gutters>
                <v-col align="right" class="pr-0" md="1">
                  <v-btn color="red-lighten-3" icon size="x-small" @click="closePracticeOverlay">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- overlay practice text line -->
              <v-row class="my-n8 py-n8" justify="center">
                <v-col align="left" md="3" offset-md="2">
                  <v-btn
                    class="mr-1"
                    :disabled="isRecording || !hasRecordingBySentenceIndex(0) || state.focusSentenceIndex === 0"
                    size="small"
                    @click="advanceToSentence(0, true)"
                  >
                    <v-icon color="primary" left size="small">mdi-page-first</v-icon>
                    First
                  </v-btn>
                  <v-btn
                    v-if="state.focusSentenceIndex !== null"
                    :disabled="isRecording || !hasRecordingBySentenceIndex(state.focusSentenceIndex - 1)"
                    size="small"
                    @click="advanceToSentence(-1)"
                  >
                    <v-icon color="primary" left size="small">mdi-skip-backward</v-icon>
                    Prev
                  </v-btn>
                </v-col>

                <v-col align="center" md="1">
                  <v-select v-model="state.surroundLines" dense :items="surroundOptions" label="Surround" variant="underlined" />
                </v-col>

                <v-col align="right" md="3">
                  <v-btn
                    v-if="state.focusSentenceIndex !== null"
                    :disabled="isRecording || !hasRecordingBySentenceIndex(state.focusSentenceIndex + 1)"
                    size="small"
                    @click="advanceToSentence(1)"
                  >
                    Next
                    <v-icon color="primary" right size="small">mdi-skip-forward</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-1"
                    :disabled="
                      isRecording ||
                      !hasRecordingBySentenceIndex(state.annotatedSentences.length - 1) ||
                      state.focusSentenceIndex === state.annotatedSentences.length - 1
                    "
                    size="small"
                    @click="advanceToSentence(state.annotatedSentences.length - 1, true)"
                  >
                    Last
                    <v-icon color="primary" right size="small">mdi-page-last</v-icon>
                  </v-btn>
                </v-col>

                <v-col class="ml-4 mt-6" md="2">
                  <v-switch
                    v-if="state.focusSentenceIndex !== null"
                    v-model="state.autoAdvance"
                    class="auto-next mt-n6 pt-n6"
                    color="primary"
                    :disabled="!hasRecordingBySentenceIndex(state.focusSentenceIndex + 1)"
                    hide-details
                    label="Auto advance"
                  />
                </v-col>
              </v-row>

              <v-row class="my-6" justify="center">
                <v-col class="text-center" md="11">
                  <span ref="practiceSpan">
                    <annotated-text
                      v-if="store.getters.student.barMitzvah && store.getters.user.user?.properties"
                      :bar-mitzvah="store.getters.student.barMitzvah"
                      :break-before-line="
                        (): boolean => {
                          if (state.tikkunStyle) {
                            return false
                          }
                          return !sentencesHaveSameResponsiveness || state.surroundLines !== 0
                        }
                      "
                      :clickable-segment="clickableSegment"
                      :clickable-sentence="clickableSentence"
                      :default-font="store.getters.user.user.properties!.font"
                      :empty-on-missing-style="true"
                      :font-size="state.fontSize"
                      :line-height="lineHeight"
                      name="practiceText"
                      :refresh="state.refreshAnnotatedText"
                      :segment-boundary-style="segmentBoundaryStyle"
                      :segment-style="segmentStyle"
                      :segmentations="segmentations"
                      :sentence-style="sentenceStyle"
                      :sentences="sentencesWithSurround"
                      :show-end-of-sentence-character="!state.tikkunStyle"
                      :show-responsive-headers="
                        () => {
                          return !sentencesHaveSameResponsiveness
                        }
                      "
                      :show-sentence-numbers="!state.tikkunStyle"
                      :start-index="surroundBeforeStartIndex"
                      :suppress-colors="
                        () => {
                          return state.tikkunStyle
                        }
                      "
                      :transformation="state.transformation as TransformationType"
                      @click-segment="handleSegmentClick"
                      @click-segment-boundary="handleSegmentBoundaryClick"
                    />
                  </span>

                  <popover :attach-id="popoverTarget" :visible="state.popoverVisible">
                    <v-card>
                      <v-card-text class="pa-1">
                        <!-- "Sing Now!" popover -->
                        <template v-if="state.popoverShowStartNow">
                          <span class="mx-4 my-5 text-h5">{{ inSingMode(state.voiceStyleSet) ? 'Sing' : 'Read' }} now!</span>
                        </template>
                        <!-- repeat popover -->
                        <template v-else-if="state.inRepeatMode">
                          <div class="pa-3 ma-3 text-h2">
                            {{ state.requiredRepeats }}
                          </div>
                        </template>
                        <template v-else>
                          <v-icon
                            v-if="unloaded() || paused()"
                            class="playable"
                            size="xxx-large"
                            @click="
                              () => {
                                if (!state.popoverSentence) {
                                  logger.error('unexpected null state.popoverSentence')
                                  return
                                }
                                if (state.popoverSentenceIndex === null) {
                                  logger.error('unexpected null state.popoverSentenceIndex')
                                  return
                                }
                                if (state.popoverSegmentIndex === null) {
                                  logger.error('unexpected null state.popoverSegmentIndex')
                                  return
                                }
                                if (!loaded()) {
                                  setLoadPlay({
                                    segmentIndex: state.popoverSegmentIndex,
                                    pitchShift: state.pitchShift,
                                    sentenceRecordingMetadataWithPitchedAudios: recordingsInStyle[state.popoverSentence.sentence().id][0],
                                  })
                                } else {
                                  play()
                                }
                              }
                            "
                            >mdi-play mdi-rotate-180</v-icon
                          >
                          <v-icon
                            v-else
                            class="pausable"
                            size="xxx-large"
                            @click="
                              () => {
                                if (!state.popoverSentence) {
                                  logger.error('unexpected null state.popoverSentence')
                                  return
                                }
                                if (playing()) {
                                  pause()
                                }
                              }
                            "
                            >mdi-pause</v-icon
                          >

                          <v-icon
                            size="xxx-large"
                            @click="
                              () => {
                                if (playing()) {
                                  pause()
                                }
                                if (!state.popoverSentence) {
                                  logger.error('unexpected null state.popoverSentence')
                                  return
                                }
                                if (state.popoverSentenceIndex === null) {
                                  logger.error('unexpected null state.popoverSentenceIndex')
                                  return
                                }
                                if (state.popoverSegmentIndex === null) {
                                  logger.error('unexpected null state.popoverSegmentIndex')
                                  return
                                }
                                setLoadPlay({
                                  segmentIndex: state.popoverSegmentIndex,
                                  pitchShift: state.pitchShift,
                                  sentenceRecordingMetadataWithPitchedAudios: recordingsInStyle[state.popoverSentence.sentence().id][0],
                                })
                              }
                            "
                            >mdi-skip-forward</v-icon
                          >

                          <v-icon size="xxx-large" @click="closePopover()">mdi-close</v-icon>
                        </template>
                      </v-card-text>
                    </v-card>
                  </popover>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col md="3" offset-md="1">
                  <v-radio-group v-model="state.readOrder" :disabled="isRecording">
                    <template #label>
                      <span class="text-caption">Practice mode</span>
                    </template>
                    <v-radio
                      v-for="readOrderOption in state.readOrderOptions"
                      :key="readOrderOption.value as string"
                      :label="readOrderOption.title as string"
                      :value="readOrderOption.value as string"
                    />
                  </v-radio-group>
                  <!--
                  <v-switch v-model="state.recordMe" color="primary" label="Record me during practice" />
                  -->
                </v-col>
                <v-col class="mt-4 d-inline-flex" md="4" offset-md="1">
                  <v-tooltip
                    bottom
                    open-delay="500"
                    :text="`Disables automatic word or segment jumps; use the ['${NEXT_KEY}'] key to jump to the next segment.`"
                  >
                    <template #activator="{ props }">
                      <span v-bind="props">
                        <v-checkbox
                          v-model="state.manualSegmentJump"
                          dense
                          :disabled="sentence.isResponsive"
                          hide-details
                          label="Manually step to next word or segment"
                      /></span>
                    </template>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-row class="mt-n4 pt-n4" justify="center">
                <v-col class="text-center" md="3">
                  <v-btn
                    v-if="!isRecording"
                    block
                    color="primary"
                    :disabled="sentence === null || state.downloading || isRecording"
                    @click="
                      () => {
                        // do not swap the order, because toggleRecord depends
                        // on isRecording, and isRecording depends on
                        // recordButtonPressed.
                        toggleRecord()
                        state.recordButtonPressed = true
                      }
                    "
                  >
                    <v-icon left>mdi-microphone</v-icon>
                    Start
                  </v-btn>
                  <v-btn
                    v-else
                    block
                    color="warning"
                    :disabled="state.backgroundSampling || (currentSegmentIndex === 0 && state.playPhase === PlayPhase.FIRST)"
                    @click="handleJumpBack"
                  >
                    >> Jump back, practice {{ REPEATS }}x
                  </v-btn>
                </v-col>
                <v-col class="text-center" md="1">
                  <v-progress-circular v-if="state.downloading || state.finalizerHandler" color="primary" indeterminate />
                  <v-icon
                    v-show="!state.finalizerHandler && !state.downloading && isRecording && !state.backgroundSampling"
                    color="red"
                    label="Recording..."
                    size="xxx-large"
                    >mdi-microphone</v-icon
                  >
                </v-col>
                <v-col md="3">
                  <v-btn
                    block
                    class="error"
                    :disabled="!isRecording || sentence === null"
                    @click="
                      () => {
                        toggleRecord()
                        state.recordButtonPressed = false
                      }
                    "
                  >
                    <v-icon left>mdi-stop</v-icon>
                    Stop
                  </v-btn>
                </v-col>
              </v-row>

              <v-row class="my-3 py-3" justify="center">
                <v-col md="3">
                  Voice sensitivity
                  <v-slider
                    v-model="state.voiceSensitivity"
                    color="primary"
                    dense
                    :disabled="sentence.isResponsive"
                    hide-details
                    :max="100"
                    :min="1"
                    thumb-label
                    @change="setVoiceSensitivity"
                  ></v-slider>
                </v-col>
                <v-col md="1"></v-col>
                <v-col md="3">
                  Post-silence pause patience
                  <v-slider
                    v-model="state.silencePatience"
                    color="primary"
                    dense
                    :disabled="sentence.isResponsive"
                    hide-details
                    :max="5"
                    :min="1"
                    thumb-label
                    @change="setSilencePatience"
                  ></v-slider>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </template>

      <!-- all text; this is the thing that sets annotatedSentences, so we cannot hide it using a v-if or v-else-if, but we can hide it using a v-show -->
      <v-row
        v-else-if="!tagWarning && state.sentences.length"
        v-show="inTextMode(state.voiceStyleSet) || !_.isEmpty(recordingsInStyle)"
        class="mt-n8 pt-n8"
      >
        <v-col lg="12" md="12" xl="10">
          <v-card>
            <v-card-text>
              <v-row class="py-4 px-3" justify="center" no-gutters>
                <v-col md="11">
                  <annotated-text
                    v-if="store.getters.user.user?.properties"
                    v-model:annotated-sentences="state.annotatedSentences as Array<AnnotatedSentence>"
                    :bar-mitzvah="store.getters.student.barMitzvah"
                    :break-before-line="
                      () => {
                        return !state.tikkunStyle || !sentencesHaveSameResponsiveness
                      }
                    "
                    :clickable-sentence="hasRecordingByAnnotatedSentence"
                    :default-font="store.getters.user.user.properties.font"
                    :font-size="state.fontSize"
                    :line-height="lineHeight"
                    name="allText"
                    :sentence-style="sentenceStyle"
                    :sentences="state.sentences"
                    :show-end-of-sentence-character="!state.tikkunStyle"
                    :show-responsive-headers="
                      () => {
                        return !sentencesHaveSameResponsiveness
                      }
                    "
                    :show-sentence-numbers="!state.tikkunStyle"
                    :suppress-colors="
                      () => {
                        return state.tikkunStyle
                      }
                    "
                    :transformation="state.transformation as TransformationType"
                    @click-sentence="handleSentenceClick"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <!-- }}} -->
</template>

<style>
/* {{{ style
 */
/* card should not change the line-height of annotated text */
.v-card__text {
  line-height: unset;
}

.auto-next .v-label {
  margin-bottom: 0 !important;
}

.torah {
  font-family: StamAshkenazCLM, sans-serif;
}

.bi:hover {
  cursor: pointer;
}

.closable:hover {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

a.disabled {
  pointer-events: none;
  color: grey;
}

.transformation-v-select .v-select__selections {
  direction: rtl;
}

.transformation-v-select-option {
  direction: rtl;
  width: 100%;
  justify-content: flex-end;
}
/* }}} */
</style>
